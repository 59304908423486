
const unicode = {
  '⅒': 1 / 10,
  '⅑': 1 / 9,
  '⅛': 1 / 8,
  '⅐': 1 / 7,
  '⅙': 1 / 6,
  '⅕': 1 / 5,
  '¼': 1 / 4,
  '⅓': 1 / 3,
  '½': 1 / 2,
  '⅖': 2 / 5,
  '⅔': 2 / 3,
  '⅜': 3 / 8,
  '⅗': 3 / 5,
  '¾': 3 / 4,
  '⅘': 4 / 5,
  '⅝': 5 / 8,
  '⅚': 5 / 6,
  '⅞': 7 / 8,
}

export const units = [
  { 'id': 'cup', 'type': 'volume', 'preferredLabel': 'cups', 'variants': ['c', 'c.', 'cup', 'cups']},
  { 'id': 'gallon', 'type': 'volume', 'preferredLabel': 'gal', 'variants': ['gal', 'gallon', 'gallons']},
  { 'id': 'ounce', 'type': 'weight', 'preferredLabel': 'oz', 'variants': ['oz', 'oz.', 'ounce', 'ounces']},
  { 'id': 'pint', 'type': 'volume', 'preferredLabel' : 'pt', 'variants': ['pt', 'pts', 'pt.', 'pint', 'pints']},
  { 'id': 'pound', 'type': 'weight', 'preferredLabel' : 'lb', 'variants': ['lb', 'lb.', 'lbs', 'pounds', 'pound']},
  { 'id': 'quart', 'type': 'weight', 'preferredLabel' : 'qt', 'variants': ['qt', 'qt.', 'qts']},
  { 'id': 'tablespoon', 'type': 'volume', 'preferredLabel' : 'tbsp', 'variants': ['tbs', 'tbsp', 'tbspn', 't', 't.', 'tablespoons', 'tablespoon']},
  { 'id': 'teaspoon', 'type': 'volume', 'preferredLabel' : 'tsp', 'variants': ['tsp', 'tspn', 't', 't.', 'teaspoon', 'teaspoons']},
  { 'id': 'gram', 'type': 'weight', 'preferredLabel' : 'g', 'variants': ['g', 'g.', "gr", "gr.", "gram", "grams"]},
  { 'id': 'kilogram', 'type': 'weight', 'preferredLabel' : 'kg', 'variants': ['kg', 'kg.', "kgr", "kgr.", "kg", "kg.", "kilogram", "kilograms"]},
  { 'id': 'liter', 'type': 'volume', 'preferredLabel' : 'L', 'variants': ['l', 'l.', "lt", "lt.", "liter", "liters"]},
  { 'id': 'milligram', 'type': 'volume', 'preferredLabel' : 'mg', 'variants': ['mg', 'mg.', "mgr", "mgr.", "milligram", "milligrams"]},
  { 'id': 'milliliter', 'type': 'volume', 'preferredLabel' : 'ml', 'variants': ['ml', 'ml.', "milliliter", "milliliters"]},
  { 'id': 'centiliters', 'type': 'volume', 'preferredLabel' : 'cl', 'variants': ['cl', 'cl.', "centiliter", "centiliters"]},
  { 'id': 'package', 'type': 'unit', 'preferredLabel' : 'pkg', 'variants': ['pkg', 'pkgs', 'package']},
  { 'id': 'stick', 'type': 'unit', 'preferredLabel' : 'sticks', 'variants': ['sticks', 'stick']},
  { 'id': 'piece', 'type': 'unit', 'preferredLabel' : 'pcs', 'variants': ['pcs', 'pcs.', 'piece']},
  { 'id': 'pinch', 'type': 'unit', 'preferredLabel' : 'pinch', 'variants': ['pinch']},
]

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function parseRawIngredientListLine(rawIngredientListLine) {
  const unicodeFractions = Object.keys(unicode).join('')
  const numericalCharacters = `0-9${unicodeFractions}`
  const unitVariants = units.flatMap((unit) => unit.variants).map(u => escapeRegExp(u)).join('|')
  const regex = new RegExp(`^(?<amount>[${numericalCharacters}\\.\\/\\s]*[${numericalCharacters}])(?<label>\\s*(?<unit>(${unitVariants})(?=\\s))?\\s*(?<name>.*)\\s*)`, 'i')
  const matches = rawIngredientListLine.trim().match(regex)

  if (!matches || !matches.groups) {
    return null
  }

  return {
    amount: parseAmount(matches.groups.amount),
    unit: matches.groups.unit,
    name: matches.groups.name,
    label: matches.groups.label
  }
}

export function parseAmount(amount) {
  const unicodeFractions = Object.keys(unicode).join('')
  const matches = amount.trim().match(`^(?<wholeNumber>[\\d.]+(?!\\s*\\/))?\\s*(((?<numerator>\\d+)\\s*\\/\\s*(?<denominator>\\d+))|(?<unicodeFraction>[${unicodeFractions}]))?`)

  if (!matches || !matches.groups) { return 0 }
  const { wholeNumber = '0', numerator = '0', denominator = '1', unicodeFraction = '' } = matches.groups

  return parseFloat(wholeNumber) + (parseFloat(numerator) / parseFloat(denominator)) + (unicode[unicodeFraction] || 0)
}
