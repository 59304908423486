import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import RecipePreview from "../components/recipe-preview"
import RichText from "../components/rich-text"

const SpecificRecipeLinks = ({ contentBlock }) => {
  return (
    <>
      {contentBlock.content && (
        <Box mb={6}>
          <RichText richTextJson={contentBlock.content.raw} />
        </Box>
      )}
      <Grid container spacing={1} zeroMinWidth>
        {contentBlock.recipeLinks.map((recipeLink, index) => {
          return (
            <Grid key={index} item xs={12}>
              <RecipePreview recipe={recipeLink} showAbstract={true} />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
export default SpecificRecipeLinks
