import _ from "lodash"

// conversion categories: sugar, cocoa butter, cocoa mass, water, oils, milk fat, other solids
const knownConversions = [
  {
    names: ["milk chocolate"], // 35%
    composition: [
      { type: "cocoa butter", percent: 30.2 },
      { type: "fat-free cocoa solids", percent: 4.9 },
      { type: "milk fat", percent: 6 },
      { type: "fat-free milk solids", percent: 15.8 },
      { type: "sugar", percent: 43.1 },
    ],
  },
  {
    names: ["dark chocolate"], // 56%
    composition: [
      { type: "cocoa butter", percent: 36.6 },
      { type: "fat-free cocoa solids", percent: 19.4 },
      { type: "sugar", percent: 44 },
    ],
  },
  {
    names: ["white chocolate"], // 29.5%
    composition: [
      { type: "cocoa butter", percent: 29.5 },
      { type: "milk fat", percent: 6.3 },
      { type: "fat-free milk solids", percent: 16.7 },
      { type: "sugar", percent: 47.5 },
    ],
  },
  {
    names: ["heavy cream", "cream", "thickened cream"],
    composition: [
      { type: "milk fat", percent: 35 },
      { type: "fat-free milk solids", percent: 5 },
      { type: "water", percent: 60 },
    ],
  },
  {
    names: ["milk", "whole milk"],
    composition: [
      { type: "milk fat", percent: 3.7 },
      { type: "fat-free milk solids", percent: 9 },
      { type: "water", percent: 87.3 },
    ],
  },
  {
    names: ["butter", "unsalted butter"],
    composition: [
      { type: "milk fat", percent: 83 },
      { type: "fat-free milk solids", percent: 2 },
      { type: "water", percent: 15 },
    ],
  },
  {
    names: ["condensed milk", "sweetened condensed milk"], //http://dairy-technology.blogspot.com/2014/01/condensed-milk-and-evaporated-milk-are.html
    composition: [
      { type: "milk fat", percent: 9 },
      { type: "fat-free milk solids", percent: 22 },
      { type: "sugar", percent: 43 },
      { type: "water", percent: 26 },
    ],
  },
  {
    names: ["evaporated milk"],
    composition: [
      { type: "milk fat", percent: 6.5 },
      { type: "fat-free milk solids", percent: 16.5 },
      { type: "water", percent: 77 },
    ],
  },
  {
    names: ["sugar"],
    composition: [{ type: "sugar", percent: 100 }],
  },
]

const findConversion = (rawIngredientName) => {
  const ingredientName = rawIngredientName
    .replace(/[^a-zA-Z\s]/g, "")
    .toLowerCase()
    .trim()

  return knownConversions.find((conversion) => {
    return (conversion.names.some((conversionName) => {
      return _.intersection(conversionName.split(' '), ingredientName.split(' ')).length > conversionName.split(' ').length / 2
    }))
  })
};

export const calculateCompositionOfIngredients = (parsedIngredientsList) => {
  const ingredientsList = parsedIngredientsList.filter((ingredient) => ingredient.amount)

  const baseComposition = ingredientsList
    .map((ingredient) => {
      const knownConversion = findConversion(ingredient.name)

      if (!knownConversion) return ingredient

      return knownConversion.composition.map((conversion) => {
        return {
          name: conversion.type,
          amount: (ingredient.amount * conversion.percent) / 100,
        }
      })
    })
    .flat()

  const totalAmount = _.sumBy(ingredientsList, "amount")

  return _.map(_.groupBy(baseComposition, "name"), (value, key) => {
    const amount = _.sum(_.map(value, "amount"))
    const percentage = (amount / totalAmount) * 100

    // return `${amount.toFixed(1)} ${key} (${percentage.toFixed(1)}%)`
    return {
      amount: amount.toFixed(2),
      name: key,
      percentage: percentage.toFixed(2)
    }
  })
}
