import React from "react"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import _ from "lodash"

import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import { parseRawIngredientListLine } from "../lib/recipe-line-parser.js"
import { calculateCompositionOfIngredients } from "../lib/recipe-composition.js"

const useStyles = makeStyles((theme) => ({
  paddedBox: {
    padding: theme.spacing(4, 4),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

const CompositionCalculator = ({ siteTitle, toggleDrawer }) => {
  const classes = useStyles()

  const extractIngredientsList = (rawIngredientsList) => {
    const parsedIngredientsList = _.compact(rawIngredientsList.split("\n").map(parseRawIngredientListLine));

    return parsedIngredientsList.map(({ amount, name }) => {
      return { amount, name }
    });
  }

  const handleIngredientListChange = (event) => {
    const rawIngredientsList = event.target.value
    const recipeComposition =
      calculateCompositionOfRawIngredients(rawIngredientsList)

    setState({
      ingredientsList: rawIngredientsList,
      recipeComposition: recipeComposition,
    })
  }

  const calculateCompositionOfRawIngredients = (rawIngredientsList) => {
    const parsedIngredientsList = extractIngredientsList(rawIngredientsList)

    return calculateCompositionOfIngredients(parsedIngredientsList).map(({ amount, name, percentage }) => {
      return `${amount} ${name} (${percentage}%)`
    })
  }

  const defaultIngredientsList =
    "200 dark chocolate\n150 heavy cream\n20 butter"

  const [state, setState] = React.useState({
    ingredientsList: defaultIngredientsList,
    recipeComposition: calculateCompositionOfRawIngredients(
      defaultIngredientsList
    ),
  })

  return (
    <Paper className={classes.paddedBox}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <Typography variant="h5">Recipe</Typography>
          <TextField
            variant="outlined"
            label="Recipe"
            value={state.ingredientsList}
            multiline
            fullWidth
            onChange={handleIngredientListChange}
          />
        </Grid>
        <Hidden smDown>
          <Grid item md={1}>
            <Typography variant="h4" component="p" gutterBottom align="center">
              →
            </Typography>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12}>
            <Typography variant="h4" component="p" align="center">
              ↓
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={5}>
          <Typography variant="h5">Recipe Composition</Typography>
          <Typography variant="body1">
            <ul>
              {state.recipeComposition.map((component) => {
                return <li>{component}</li>
              })}
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default CompositionCalculator
