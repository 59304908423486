import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import RichText from "../components/rich-text"
import GelatinConverter from "../components/gelatin-converter"
import IngredientListScaler from "../components/ingredient-list-scaler"
import ScalerToIngredient from "../components/scaler-to-ingredient"
import SpecificRecipeLinks from "../components/specific-recipe-links"
import CompositionCalculator from "../components/composition-calculator"
import IngredientAggregator from "../components/ingredient-aggregator"
import RecipeComparer from "../components/recipe-comparer"

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  recipeBody: {
    paddingTop: theme.spacing(4),
  },
  responsiveVideo: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
    height: 0,
  },
  responsiveVideoIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}))

const InsightTemplate = ({ data }) => {
  const classes = useStyles()
  const insight = data.contentfulInsight

  const RecipeResizerGooglePlayDownloadButton = () => {
    return (
      <a href="https://play.google.com/store/apps/details?id=com.dessertisans.recipe_resizer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          style={{ maxWidth: "240px", marginBottom: "0" }}
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    )
  }

  const customComponents = {
    GelatinConverter: GelatinConverter,
    IngredientsListScaler: IngredientListScaler,
    ScalerToIngredient: ScalerToIngredient,
    CompositionCalculator: CompositionCalculator,
    IngredientAggregator: IngredientAggregator,
    RecipeComparer: RecipeComparer,
    RecipeResizerGooglePlayDownloadButton:
      RecipeResizerGooglePlayDownloadButton,
  }

  return (
    <Layout location={data.location}>
      <Seo title={insight.title} />
      <div style={{ background: "#fff" }}>
        <Container maxWidth="md" className={classes.header}>
          <Typography variant="h1" component="h1" gutterBottom align="center">
            {insight.title}
          </Typography>
        </Container>

        {insight.heroImage && (
          <Container maxWidth="md">
            <GatsbyImage
              image={insight.heroImage.gatsbyImageData}
              alt={insight.title}
            />
          </Container>
        )}

        <Container maxWidth="sm" className={classes.recipeBody}>
          <Box mb={4}>
            <Typography variant="body2" gutterBottom>
              Published: {insight.createdAt} | Last Updated: {insight.updatedAt}
            </Typography>
          </Box>

          {insight.content && (
            <Box mb={6}>
              <RichText richTextJson={insight.content.raw} />
            </Box>
          )}
        </Container>

        <Container maxWidth="md" style={{ padding: 0 }}>
          {(insight.contentBlocks || []).map((contentBlock) => {
            switch (contentBlock.__typename) {
              case "ContentfulCbRichText":
                return (
                  <Container key={contentBlock.id} maxWidth="sm">
                    <Box mb={6}>
                      <RichText richTextJson={contentBlock.text.raw} />
                    </Box>
                  </Container>
                )
              case "ContentfulCbAnchorButton":
                return (
                  <Container key={contentBlock.id} maxWidth="sm">
                    <Box mb={6}>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        href={`#${contentBlock.anchorId}`}
                      >
                        {contentBlock.buttonText}
                      </Button>
                    </Box>
                  </Container>
                )
              case "ContentfulCbSpecificRecipeLinks":
                return (
                  <Container key={contentBlock.id} maxWidth="sm">
                    <Box mb={6}>
                      <SpecificRecipeLinks contentBlock={contentBlock} />
                    </Box>
                  </Container>
                )
              case "ContentfulCbImage":
                return (
                  <Container key={contentBlock.id} maxWidth="md">
                    <Box mb={6}>
                      <GatsbyImage
                        image={contentBlock.image.gatsbyImageData}
                        alt={contentBlock.image.title}
                      />
                    </Box>
                  </Container>
                )
              case "ContentfulCbVideo":
                return (
                  <Container key={contentBlock.id} maxWidth="md">
                    <Box mb={6} className={classes.responsiveVideo}>
                      <iframe
                        className={classes.responsiveVideoIframe}
                        title={contentBlock.title}
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${contentBlock.youtubeVideoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Box>
                  </Container>
                )
              case "ContentfulCbCustom":
                const CustomComponent =
                  customComponents[contentBlock.componentId]
                return (
                  <React.Fragment key={contentBlock.id}>
                    <Container maxWidth="sm">
                      {contentBlock.showTitle && contentBlock.title && (
                        <Typography
                          variant="h2"
                          gutterBottom
                          id="gelatin-conversion-calculator"
                        >
                          {contentBlock.title}
                        </Typography>
                      )}
                    </Container>
                    <Container
                      maxWidth={
                        contentBlock.componentId ===
                        "RecipeResizerGooglePlayDownloadButton"
                          ? "sm"
                          : "md"
                      }
                    >
                      <Box mb={6}>
                        <CustomComponent contentBlock={contentBlock} />
                      </Box>
                    </Container>
                  </React.Fragment>
                )
              default:
                return null
            }
          })}
        </Container>
      </div>
    </Layout>
  )
}

export default InsightTemplate

export const pageQuery = graphql`
  query InsightBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulInsight(slug: { eq: $slug }) {
      title
      slug
      createdAt(formatString: "MMMM Do, YYYY")
      updatedAt(formatString: "MMMM Do, YYYY")
      content {
        raw
      }
      contentBlocks {
        __typename
        ... on Node {
          ... on ContentfulCbRichText {
            id
            text {
              raw
            }
          }
          ... on ContentfulCbCustom {
            id
            title
            showTitle
            componentId
          }
          ... on ContentfulCbImage {
            id
            image {
              title
              gatsbyImageData
            }
          }
          ... on ContentfulCbVideo {
            id
            title
            youtubeVideoId
          }
          ... on ContentfulCbAnchorButton {
            id
            title
            showTitle
            buttonText
            anchorId
          }
          ... on ContentfulCbSpecificRecipeLinks {
            id
            content {
              raw
            }
            recipeLinks {
              __typename
              ... on ContentfulRecipe {
                title
                slug
                abstract {
                  abstract
                }
                createdAt(formatString: "MMMM Do, YYYY")
                thumbnailImage {
                  title
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
      heroImage {
        gatsbyImageData
      }
    }
    insightMeta: contentfulInsight(slug: { eq: $slug }) {
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
    }
  }
`
