import React, { useState } from "react"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import _ from "lodash"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import TextField from "@material-ui/core/TextField"
import { parseRawIngredientListLine } from "../lib/recipe-line-parser.js"
import { calculateCompositionOfIngredients } from "../lib/recipe-composition.js"

const useStyles = makeStyles((theme) => ({
  headerCell: {
    fontWeight: 'bold'
  },
  paddedBox: {
    padding: theme.spacing(4, 4),
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
  difference: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  }
}))

const RecipeComparer = () => {
  const classes = useStyles()

  const handleIngredientListChange = (listKey) => (event) => {
    const rawIngredientsList = event.target.value
    const newRawIngredientLists = { ...rawIngredientLists, [listKey]: rawIngredientsList}
    setRawIngredientsLists(newRawIngredientLists)
    updateComparisons(newRawIngredientLists)
    updateCompositions(newRawIngredientLists)
  }

  const updateComparisons = (ingredientLists) => {
    const newComparisons = generateComparisons(ingredientLists)

    setComparisons(newComparisons)
  }

  const updateCompositions = (ingredientLists) => {
    const newCompositions = generateCompositions(ingredientLists)

    setCompositions(newCompositions)
  }

  const generateCompositions = (ingredientLists) => {
    const parsedIngredientsListA = _.compact(ingredientLists.a.split("\n").map(parseRawIngredientListLine))
    const parsedIngredientsListB = _.compact(ingredientLists.b.split("\n").map(parseRawIngredientListLine))


    const compositionA = calculateCompositionOfIngredients(parsedIngredientsListA)
    const compositionB = calculateCompositionOfIngredients(parsedIngredientsListB)
    const allIngredients = _.uniq(_.map(compositionA.concat(compositionB), 'name'))

    return allIngredients.map((ingredient) => {
      const aIngredient = _.find(compositionA, { name: ingredient })
      const bIngredient = _.find(compositionB, { name: ingredient })
      const aPercentage = aIngredient ? aIngredient.percentage : null
      const bPercentage = bIngredient ? bIngredient.percentage : null
      const difference = round(bPercentage - aPercentage)

      return {
        name: ingredient,
        aPercentage,
        bPercentage,
        difference
      }
    })
  }

  const generateComparisons = (ingredientLists) => {
    const parsedIngredientsListA = _.compact(ingredientLists.a.split("\n").map(parseRawIngredientListLine))
    const parsedIngredientsListB = _.compact(ingredientLists.b.split("\n").map(parseRawIngredientListLine))

    const totalA = _.sumBy(parsedIngredientsListA, "amount");
    const totalB = _.sumBy(parsedIngredientsListB, "amount");
    const allIngredients = _.uniq(_.map(parsedIngredientsListA.concat(parsedIngredientsListB), 'name'))

    return allIngredients.map((ingredient) => {
      const aIngredient = _.find(parsedIngredientsListA, { name: ingredient })
      const bIngredient = _.find(parsedIngredientsListB, { name: ingredient })
      const aPercentage = aIngredient ? round(aIngredient.amount / totalA * 100) : null
      const bPercentage = bIngredient ? round(bIngredient.amount / totalB * 100) : null
      const difference = round(bPercentage - aPercentage)

      return {
        name: ingredient,
        aPercentage,
        bPercentage,
        difference
      }
    })
  }

  const round = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }

  const multilineString = (string) => {
    return string.replace(/(^[\s|\n]+)|([\s|\n]+$)/g, '').split('\n').map((line) => line.replace(/^\s+/, '')).join('\n')
  }

  const defaultIngredientsListA = multilineString(`
    SAMPLE RECIPE A - REPLACE ME

    Pastry Cream
    35g milk
    25g cornflour
    55g egg yolks
    1g salt
    280g milk
    35g cream
    70g caster sugar`)

  const defaultIngredientsListB = multilineString(`
    SAMPLE RECIPE B - REPLACE ME

    Pastry Cream
    40g milk
    30g cornflour
    60g egg yolks
    1g salt
    300g milk
    50g cream
    90g caster sugar`)

  const [rawIngredientLists, setRawIngredientsLists] = useState(
    {
      a: defaultIngredientsListA,
      b: defaultIngredientsListB
    }
  )

  const [comparisons, setComparisons] = useState(generateComparisons(rawIngredientLists))

  const [compositions, setCompositions] = useState(generateCompositions(rawIngredientLists))

  return (
    <Paper className={classes.paddedBox}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right" className={classes.headerCell}>Recipe A</TableCell>
              <TableCell align="right" className={classes.headerCell}>Recipe B</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Ingredient Lists</TableCell>
              <TableCell align="right">
                <TextField
                  variant="outlined"
                  label="Recipe A"
                  value={rawIngredientLists.a}
                  multiline
                  fullWidth
                  onChange={handleIngredientListChange('a')}
                />
              </TableCell>
              <TableCell align="right">
                <TextField
                  variant="outlined"
                  label="Recipe B"
                  value={rawIngredientLists.b}
                  multiline
                  fullWidth
                  onChange={handleIngredientListChange('b')}
                />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow className={classes.headerRow}>
              <TableCell className={classes.largeHeaderCell} colSpan={4}>
                <Typography variant="h5">Recipe Ingredient Comparison</Typography>
              </TableCell>
            </TableRow>

            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell}>Ingredient</TableCell>
              <TableCell className={classes.headerCell} align="right">Recipe A Percentage</TableCell>
              <TableCell className={classes.headerCell} align="right">Recipe B Percentage</TableCell>
              <TableCell className={classes.headerCell} align="right">Difference</TableCell>
            </TableRow>

            {comparisons.map((comparisonItem) => {
              const differenceClass = comparisonItem.difference > 0 ? classes.green : comparisonItem.difference < 0 ? classes.red : ''
              const ArrowIcon = comparisonItem.difference > 0 ? ArrowDropUp : comparisonItem.difference < 0 ? ArrowDropDown : React.Fragment

              return (
                <TableRow key={comparisonItem.name}>
                  <TableCell>{comparisonItem.name}</TableCell>
                  <TableCell align="right">{comparisonItem.aPercentage}%</TableCell>
                  <TableCell align="right">{comparisonItem.bPercentage}%</TableCell>
                  <TableCell align="right" className={[differenceClass, classes.difference]}><ArrowIcon style={{ width: '17px', height: '17px' }}/>{comparisonItem.difference}%</TableCell>
                </TableRow>
              )
            })}

            <TableRow className={classes.headerRow}>
              <TableCell colSpan={4} />
            </TableRow>

            <TableRow className={classes.headerRow}>
              <TableCell className={classes.largeHeaderCell} colSpan={4}>
                <Typography variant="h5">Recipe Composition Comparison</Typography>
              </TableCell>
            </TableRow>

            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell}>Base Ingredient</TableCell>
              <TableCell className={classes.headerCell} align="right">Recipe A Percentage</TableCell>
              <TableCell className={classes.headerCell} align="right">Recipe B Percentage</TableCell>
              <TableCell className={classes.headerCell} align="right">Difference</TableCell>
            </TableRow>

            {compositions.map((comparisonItem) => {
              const differenceClass = comparisonItem.difference > 0 ? classes.green : comparisonItem.difference < 0 ? classes.red : ''
              const ArrowIcon = comparisonItem.difference > 0 ? ArrowDropUp : comparisonItem.difference < 0 ? ArrowDropDown : React.Fragment

              return (
                <TableRow key={comparisonItem.name}>
                  <TableCell>{comparisonItem.name}</TableCell>
                  <TableCell align="right">{comparisonItem.aPercentage}%</TableCell>
                  <TableCell align="right">{comparisonItem.bPercentage}%</TableCell>
                  <TableCell align="right" className={[differenceClass, classes.difference]}><ArrowIcon style={{ width: '17px', height: '17px' }}/>{comparisonItem.difference}%</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
export default RecipeComparer
