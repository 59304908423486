import React from "react"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import _ from "lodash"

import TextField from "@material-ui/core/TextField"
import { parseRawIngredientListLine } from "../lib/recipe-line-parser.js"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles((theme) => ({
  paddedBox: {
    padding: theme.spacing(4, 4),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

const IngredientAggregator = () => {
  const classes = useStyles()

  const groupSameIngredients = (ingredientsList) => {
    return _.map(
      _.groupBy(
        ingredientsList,
        (ingredient) =>
          (ingredient.unit ? ingredient.unit + " " : "") + ingredient.name
      ),
      (ingredients, ingredientLabel) => {
        return {
          amount: _.sumBy(ingredients, "amount"),
          label: ingredientLabel,
          name: ingredients[0].name,
        }
      }
    )
  }

  const groupSimilarIngredients = (ingredients) => {
    return _.flatten(
      _.reverse(
        _.sortBy(
          _.map(
            _.reduce(
              ingredients,
              (ingredientGroups, ingredient) => {
                const group = _.find(ingredientGroups, (similarGroup) => {
                  return _.some(similarGroup, (groupedIngredient) => {
                    return _.intersection(
                      groupedIngredient.name.split(" "),
                      ingredient.name.split(" ")
                    ).length
                  })
                })

                if (group) {
                  return ingredientGroups.map((similarGroup) => {
                    if (similarGroup === group) {
                      return [...similarGroup, ingredient]
                    } else {
                      return similarGroup
                    }
                  })
                } else {
                  return [...ingredientGroups, [ingredient]]
                }
              },
              []
            ),
            (ingredientGroup) => _.reverse(_.sortBy(ingredientGroup, "amount"))
          ),
          (ingredientGroup) => _.sumBy(ingredientGroup, "amount")
        )
      )
    )
  }

  const aggregateIngredients = (ingredientsList) => {
    return _.map(
      groupSimilarIngredients(groupSameIngredients(ingredientsList)),
      (listItem) => `${listItem.amount}${listItem.label}`
    )
  }

  const handleIngredientListChange = (event) => {
    const rawIngredientsList = event.target.value
    const parsedIngredientsList = _.compact(
      rawIngredientsList.split("\n").map(parseRawIngredientListLine)
    )
    const aggregatedIngredients = aggregateIngredients(parsedIngredientsList)

    setState({
      ingredientsList: rawIngredientsList,
      aggregatedIngredients: aggregatedIngredients,
    })
  }

  const defaultIngredientsList = `SAMPLE RECIPE - REPLACE ME

Buttercream
55g milk
42g egg yolks
67g caster sugar
244g unsalted butter

Italian Meringue
24g water
55g caster sugar
36g egg whites

Pastry Cream
35g milk
25g cornflour
55g egg yolks
1g salt
280g milk
35g cream
70g caster sugar
  `

  const [state, setState] = React.useState({
    ingredientsList: defaultIngredientsList,
    aggregatedIngredients: aggregateIngredients(
      _.compact(
        defaultIngredientsList.split("\n").map(parseRawIngredientListLine)
      )
    ),
  })

  return (
    <Paper className={classes.paddedBox}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <Typography variant="h5">Full Recipe</Typography>
          <TextField
            variant="outlined"
            label="Recipe"
            value={state.ingredientsList}
            multiline
            fullWidth
            onChange={handleIngredientListChange}
          />
        </Grid>
        <Hidden smDown>
          <Grid item md={1}>
            <Typography variant="h4" component="p" gutterBottom align="center">
              →
            </Typography>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12}>
            <Typography variant="h4" component="p" align="center">
              ↓
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={5}>
          <Typography variant="h5">Total ingredients</Typography>
          <Typography variant="body1">
            <ul>
              {state.aggregatedIngredients.map((ingredientLine) => {
                return <li>{ingredientLine}</li>
              })}
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default IngredientAggregator
